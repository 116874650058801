import m from 'mithril';
import { SELECTORS } from '~/doc';
import useGalleryStore, { findVisibleItems } from './state';
import { debounce } from '~/utils/debounce';
import Gallery from './strip';

const { getState } = useGalleryStore;

const {
  setWidth,
  setPosition,
  updateNavigation,
} = getState();

export default function initGallery() {
    var galleryEl = document.getElementsByClassName(SELECTORS.gallery);

    if (galleryEl && galleryEl.length) {
        m.mount(galleryEl[0], Gallery);
    }

    function updateGallerySize() {
        if (galleryEl && galleryEl.length) {
            let dom = galleryEl[0].firstChild;
            setWidth(dom.offsetWidth);
            setPosition(dom.scrollLeft);
        }

        updateNavigation();
    }

    window.addEventListener('resize', debounce(updateGallerySize, 64), true);
}

