import m from 'mithril';

export const IconClose = {
  view: function() {
    return m('svg[viewBox="0 0 24 24"]',
      m('path[d="M18.4,8.1c0.8-0.8,0.8-2,0-2.8l0,0L12,11.8L6.4,6.2L5,7.6l5.6,5.6l-5,5c-0.8,0.8-0.8,2,0,2.8l0,0l6.4-6.4l5.6,5.6l1.4-1.4l-5.6-5.6L18.4,8.1z"]')
    );
  }
};

export const IconLeft = {
  /*
        <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 17L9.5 12L14.5 7" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        */
  view: function() {
    return m('svg[viewBox="0 0 24 24"][fill="none"]',
      m('path[d="M14.5 17L9.5 12L14.5 7"][stroke-linecap="round"][stroke-linejoin="round"]')
    );
  }
};

export const IconRight = {
  /*
         <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 7L14.5 12L9.5 17" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        */
  view: function() {
    return m('svg[viewBox="0 0 24 24"][fill="none"]',
      m('path[d="M9.5 7L14.5 12L9.5 17"][stroke-linecap="round"][stroke-linejoin="round"]')
    );
  }
};
