import { openClass, showingClass, SELECTORS } from './doc';
import { forEach } from '~/utils/forEach';

export default function initToggles() {
    const menuEls = document.getElementsByClassName(SELECTORS.navMenu);
    const menuBtnEls = document.getElementsByClassName(SELECTORS.navMenuBtn);
    const contactToggleEls = document.getElementsByClassName(SELECTORS.contactTgl);

    function toggleMenu() {
        forEach(menuEls, function(el) {
            if (!el) {
                return;
            }
            el.classList.toggle(openClass);
        });
    }

    function toggleContact(e) {
        if (e) {
            e.preventDefault();
        }

        var modalEls = document.getElementsByClassName(SELECTORS.contactModal);
        if (!modalEls || !modalEls[0]) {
            return;
        }
        modalEls[0].classList.toggle(showingClass);
    }

    forEach(menuBtnEls, function(el) {
        if (!el) {
            return;
        }
        el.addEventListener('click', toggleMenu);
    });

    forEach(contactToggleEls, function(el) {
        if (!el) {
            return;
        }
        el.addEventListener('click', toggleContact);
    });
}
