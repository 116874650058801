
import initForms from './form';
import initToggles from './toggles';
import initGallery from './gallery';


export default function init() {
  document.addEventListener('DOMContentLoaded', function(loadEvent) {
    initToggles();
    initForms();
    initGallery();
  });
}
