// forEach helper because HTMLCollection doesn't have it
export function forEach(list, func) {
  if (!list || !list.length) {
    return;
  }
  for (var i = 0; i < list.length; i++) {
    func(list[i], i);
  }
};

export default forEach;
