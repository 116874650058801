import m from 'mithril';
import useGalleryStore from './state';
import { IconClose, IconLeft, IconRight } from './icons';

const { getState } = useGalleryStore;

const {
  clearSelectedItem,
  nextItem,
  previousItem,
} = getState();

export const GalleryPopover = {
  view: function() {
    let { selectedItem } = getState();
    let isShowing = selectedItem !== null;

    return m('.popover', {
      class: isShowing ? 'is-showing' : '',
    }, [
      m('.popover__esc', {
        onclick: clearSelectedItem,
      }),
      m('.popover__wrap', m('.popover__box', [
        m('span.popover__box__close', { onclick: clearSelectedItem }, m(IconClose)),
        m('span.popover__box__nav.popover__box__nav--left', { onclick: previousItem }, m(IconLeft)),
        m('span.popover__box__nav.popover__box__nav--right', { onclick: nextItem }, m(IconRight)),
        isShowing ? m('img', { src: selectedItem.srcLarge, alt: selectedItem.alt }) : null
      ]))
    ]);
  }
};

export default GalleryPopover;

