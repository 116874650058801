const DEBUG = Boolean(import.meta?.env?.DEV);

function noop() {
  return;
};

export const log = DEBUG ? console.log : noop;

export default log;

