import { SELECTORS } from './doc';
import { forEach } from '~/utils/forEach';

export default function initForms() {
    const contactForms = document.getElementsByClassName(SELECTORS.contactForm);

    function initForm(form) {
        if (!form) {
            return;
        }

        var els = form.elements;
        var inputs = {
            mainText: els['mainText'],
            name: els['fullName'],
            email: els['email'],
            phone: els['phone'],
            submit: els['submit'],
        };

        // don't do anything for now I don't care about this form
    }

    forEach(contactForms, initForm);
}
