export const openClass = 'is-open';
export const showingClass = 'is-showing';

export const SELECTORS = {
  contactTgl: 'js-contact-toggle',
  contactForm: 'js-contact-form',
  contactModal: 'js-contact-modal',
  gallery: 'js-gallery',
  navMenu: 'js-nav-menu',
  navMenuBtn: 'js-nav-menu-icon',
};

