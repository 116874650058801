import m from 'mithril';
import useGalleryStore, { findVisibleItems } from './state';
import { GalleryPopover } from './popover';
import { IconLeft, IconRight } from './icons';
import { debounce } from '~/utils/debounce';
import { log } from '~/utils/log';

const { getState } = useGalleryStore;

const {
  clearSelectedItem,
  nextItem,
  previousItem,
  loadItems,
  setItem,
  setPosition,
  setWidth,
  setLoadPosition,
  setSelectedItem,
  updateNavigation,
} = getState();

export const  GalleryItem = {
  view: function(vnode) {
    let index = vnode.attrs.index;
    let item = vnode.attrs.item;
    let onimgload = vnode.attrs.onimgload;

    return m('li.gallery-strip__item', 
      m('img.gallery-strip__img', {
        src: item.src,
        alt: item.id,
        title: item.alt,
        onload: onimgload ? function(e) {
          onimgload(e, index);
        } : void 0,
        onclick: function() {
          setSelectedItem(item);
        }
      })
    );
  }
};

export const Gallery = {
  gonextpage: function() {
    let state = getState();
    let visible = findVisibleItems(state);
    let pageSize = visible.length;
    let maxIdx = state.offsets.length - 1;
    let next = Math.min(maxIdx, visible[pageSize - 1] + 1);

    log('⏭️ Next Page Index: ', next);

    if (isNaN(next)) {
      log('🐛 Page Selection Debug: ', visible, state)
    }

    if (next && this.dom) {
      let node = this.dom.firstChild.childNodes[next];
      if (node && node.scrollIntoView && next < maxIdx - pageSize) {
        node.scrollIntoView({
          block: 'nearest',
          inline: 'start',
          behavior: 'smooth'
        });
      } else {
        this.dom.scroll({
          top: 0,
          left: state.offsets[next],
          behavior: 'smooth'
        });
      }
    }
  },

  goprevpage: function () {
    let state = getState();
    let visible = findVisibleItems(state);
    let prev = Math.max(0, visible[0] - 2);

    log('⏮️ Prev Page Index: ', prev);

    if (isNaN(prev)) {
      log('🐛 Page Selection Debug: ', visible, state)
    }

    if (!isNaN(prev) && this.dom) {
      // skip jumping to the second index and just go to the start
      prev = prev === 1 ? 0 : prev;

      var node = this.dom.firstChild.childNodes[prev];
      if (node && node.scrollIntoView) {
        node.scrollIntoView({
          block: 'nearest',
          inline: 'start',
          behavior: 'smooth'
        });
      } else {
        this.dom.scroll({
          top: 0,
          left: Math.max(0, state.offsets[prev]),
          behavior: 'smooth'
        });
      }
    }
  },

  oncreate: function(vnode) {
    let dom = vnode.dom;
    log('🤖 gallery initialized with size: ', dom.offsetWidth);

    setWidth(dom.offsetWidth);
    setPosition(dom.scrollLeft);

    loadItems();

    // check the load position after a few ms because some browsers like to reset it
    setTimeout(() => {
      if (getState().loadPosition === null) {
        setLoadPosition(dom.scrollLeft);
      }
    }, 100);
  },

  onimgload: function(e, i) {
    if (!e && !e.target) {
      return;
    }
    setItem(e.target.offsetWidth, e.target.x, i);
  },

  onscroll: function(e) {
    if (getState().loadPosition === null) {
      setLoadPosition(e.target.scrollLeft);
    }

    setPosition(e.target.scrollLeft);

    updateNavigation();
  },

  view: function(vnode) {
    let { canScrollLeft, canScrollRight, items } = getState();
    let onimgload = vnode.state.onimgload.bind(vnode);
    let onscrollend = vnode.state.onscroll.bind(vnode);

    return m('ul.gallery-strip', { onscroll: debounce(vnode.state.onscroll.bind(vnode), 64), onscrollend: onscrollend }, [
      m('.wrapper', items.map(function(item, index) {
        return m(GalleryItem, { key: item.id, index: index, item: item, onimgload: onimgload })
      })),
      m('span.gallery-strip__nav.gallery-strip__nav--left.hide--palm', {
        class: canScrollLeft ? '' : 'is-disabled',
        onclick: vnode.state.goprevpage.bind(vnode),
      }, m(IconLeft)),
      m('span.gallery-strip__nav.gallery-strip__nav--right.hide--palm', {
        class: canScrollRight ? '' : 'is-disabled',
        onclick: vnode.state.gonextpage.bind(vnode),
      }, m(IconRight)),
      m(GalleryPopover)
    ]);
  }
};

export default Gallery;
